import {ConfirmationStatus, UserStatus} from '@yandex-lavka-birds/api-clients/dist/clients/b2b-auth-api/types';
import * as B2bIdmTypes from '@yandex-lavka-birds/api-clients/dist/clients/b2b-idm-api/types';
import {IdentityBasketUserKind} from '@yandex-lavka-birds/b2b-common';
import type {SearchPaginationTokenType} from '@yandex-lavka-birds/b2b-idp-provider';
import {type Infer, array, enums, object, optional, string} from 'superstruct';

import {IdmUserRoleStatus, serviceStruct} from 'types/idm';

import type {Company} from './companies';
import type {Role} from './roles';

export {UserStatus, ConfirmationStatus, IdentityBasketUserKind};

export type UserOnRead = B2bIdmTypes.UserOnRead;

export type IdpUser = UserOnRead & {roles?: Role[]};

export type UserKind = B2bIdmTypes.UserKind;
export const UserKind = B2bIdmTypes.UserKind;

export interface UserRole {
    userId: string;
    path: string;
    companyId: string;
}

export interface UserConstructorRole {
    id: number;
    userId: string;
    roleNodeId: number;
}

export interface User {
    id: string;
    companyId: string;
    roles?: UserRole[];
    company?: Company;
}

export type GetUserResponse = IdpUser | undefined;

export type GetUsersResponse = B2bIdmTypes.GetIdpUsersResponse;

export type GetUsersByIdsResponse = UserOnRead[];

export type GetUsersPaginationToken = SearchPaginationTokenType;

const UserKindStruct = enums(Object.values(IdentityBasketUserKind));

export const GetUserStruct = object({
    user_id: string(),
    user_kind: optional(UserKindStruct)
});

export type GetUserQuery = Infer<typeof GetUserStruct>;

export const DeleteUserBodyStruct = object({
    userId: string()
});

export type DeleteUserBody = Infer<typeof DeleteUserBodyStruct>;

export const RestoreUserBodyStruct = object({
    userId: string()
});

export type RestoreUserBody = Infer<typeof RestoreUserBodyStruct>;

const {SortDirection, GetUsersSortBy} = B2bIdmTypes;

export const GetUsersQueryStruct = object({
    search_text: optional(string()),
    user_kind: optional(UserKindStruct),
    services: optional(array(serviceStruct)),
    sort_by: optional(enums(Object.values(GetUsersSortBy))),
    sort_direction: optional(enums(Object.values(SortDirection))),
    user_role_statuses: optional(array(enums(Object.values(IdmUserRoleStatus)))),
    // используется только без search_text
    pagination_token: optional(string())
});

export type GetUsersQuery = Infer<typeof GetUsersQueryStruct>;

export const GetUsersByIdsBodyStruct = object({
    userIds: array(string())
});

export type GetUsersByIdsBody = Infer<typeof GetUsersByIdsBodyStruct>;

export const CreateUserBodyStruct = object({
    firstName: string(),
    lastName: string(),
    email: string()
});

export type CreateUserBody = Infer<typeof CreateUserBodyStruct>;

export const UpdateUserBodyStruct = object({
    id: string(),
    fullUsername: string(),
    firstName: optional(string()),
    lastName: optional(string()),
    email: optional(string()),
    name: optional(string()),
    picture: optional(string()),
    phoneNumber: optional(string()),
    status: optional(enums(Object.values(UserStatus)))
});

export type UpdateUserBody = Infer<typeof UpdateUserBodyStruct>;

export const UpdateUserStatusBodyStruct = object({
    fullUsername: string(),
    status: enums(Object.values(UserStatus))
});

export type UpdateUserStatusBody = Infer<typeof UpdateUserStatusBodyStruct>;

export const ResetUserPasswordBodyStruct = object({
    fullUsername: string()
});

export type ResetUserPasswordBody = Infer<typeof ResetUserPasswordBodyStruct>;

export interface UploadImportListUser {
    email?: string;
    login?: string;
    firstName?: string;
    lastName?: string;
}

export type UploadImportListErrors = Partial<Record<keyof UploadImportListUser, string[]>>;

export interface UploadImportListItem {
    values: UploadImportListUser;
    errors?: UploadImportListErrors;
}

export type UploadImportListResponse = UploadImportListItem[];

export const CreateUsersMultipleBodyStruct = object({
    users: array(CreateUserBodyStruct)
});

export type CreateUsersMultipleBody = Infer<typeof CreateUsersMultipleBodyStruct>;

export enum UsersImportStatus {
    PROGRESS = 'progress',
    SUCCESS = 'success',
    FAIL = 'fail'
}

export const LogoutUserGloballyBodyStruct = object({
    fullUsername: string(),
    userKind: optional(UserKindStruct)
});

export type LogoutUserGloballyBody = Infer<typeof LogoutUserGloballyBodyStruct>;
