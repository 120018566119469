import {Api, BaseRequestClient, DummyClientLogger} from '@yandex-lavka-birds/client';

import {applyMiddlewares} from 'client/api/client/middlewares';
import {API_VERSION} from 'constants/request';
import {ROUTES} from 'constants/routes';
import type {LogErrorBody} from 'types/error-logger';

const {LOGGER} = ROUTES.API.V1;
const logger = new DummyClientLogger();

export const api = new Api({
    logger,
    apiVersion: API_VERSION
});

applyMiddlewares(api);

class LoggerClient extends BaseRequestClient {
    protected _base = LOGGER.BASE;
    protected _api = api;

    logError(body: LogErrorBody) {
        return this._post<LogErrorBody, void>(LOGGER.ERROR, {body});
    }
}

export const loggerClient = new LoggerClient();
