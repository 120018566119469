import {useQueryClient} from '@tanstack/react-query';
import {useActions} from '@yandex-lavka-birds/client';
import React from 'react';
import {useSelector} from 'react-redux';

import {getUsersListQueryKey} from 'client/api/users';
import {useGetLastUsersImport} from 'client/api/users/use-get-last-users-import';
import {notificationsActions} from 'client/scenes/notifications/notifications-reducer';
import {NotificationType} from 'client/scenes/notifications/types';
import {ClientUsersImportStatus} from 'client/scenes/users/users-import-controller/types';
import {usersImportActions} from 'client/scenes/users/users-import-controller/users-import-reducer';
import {UsersImportStatus} from 'types/users';

import {selectUsersImportState} from './users-import-selectors';

const MIN_PROGRESS_POLLING_INTERVAL = 10 * 1000;
const MAX_PROGRESS_POLLING_INTERVAL = 5 * 60 * 1000;

/**
 * Отвечает за поллинг результатов импорта, обновление статуса и добавление постоянного уведомления об импорте
 */
export function UsersImportController() {
    const queryClient = useQueryClient();

    const {status: importStatus} = useSelector(selectUsersImportState);
    const setImportState = useActions(usersImportActions.setState);

    const showNotification = useActions(notificationsActions.show);

    const [refetchInterval, setRefetchInterval] = React.useState<number | false>(
        importStatus === ClientUsersImportStatus.PROGRESS ? MIN_PROGRESS_POLLING_INTERVAL : false
    );

    const {data} = useGetLastUsersImport({
        refetchInterval,
        onSuccess: () => {
            setRefetchInterval((prevState) =>
                prevState ? Math.min(prevState * 2, MAX_PROGRESS_POLLING_INTERVAL) : false
            );
        }
    });

    React.useEffect(() => {
        if (data?.status === UsersImportStatus.PROGRESS) {
            setImportState({status: ClientUsersImportStatus.PROGRESS, lastImportId: data.id});
        }

        if (data?.status === UsersImportStatus.SUCCESS) {
            setImportState({
                status: ClientUsersImportStatus.SUCCESS,
                lastImportId: data.id,
                usersAdded: data.usersCountSuccess,
                usersFailed: data.usersCountFail
            });
            void queryClient.invalidateQueries(getUsersListQueryKey());
        }

        if (data?.status === UsersImportStatus.FAIL) {
            setImportState({
                status: ClientUsersImportStatus.FAIL,
                lastImportId: data.id,
                usersAdded: data.usersCountSuccess,
                usersFailed: data.usersCountFail
            });
            void queryClient.invalidateQueries(getUsersListQueryKey());
        }
    }, [data?.id, data?.status, data?.usersCountFail, data?.usersCountSuccess, queryClient, setImportState]);

    React.useEffect(() => {
        if (importStatus === ClientUsersImportStatus.PROGRESS) {
            showNotification({type: NotificationType.USERS_IMPORT, permanent: true});
            setRefetchInterval(MIN_PROGRESS_POLLING_INTERVAL);
        } else {
            setRefetchInterval(false);
        }
    }, [importStatus, showNotification]);

    return null;
}
