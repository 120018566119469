import {QueryClient} from '@tanstack/react-query';

import {retry} from 'client/api/client/config';
import {config} from 'client/config';

const queryConfig = {
    ...config.app.queryConfig,
    defaultOptions: {
        ...config.app.queryConfig.defaultOptions,
        queries: {
            ...config.app.queryConfig.defaultOptions?.queries,
            retry
        }
    }
};

export const queryClient = new QueryClient(queryConfig);
