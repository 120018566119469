import {OrganizationBrand} from '@yandex-lavka-birds/b2b-common';
import {block} from '@yandex-lavka-birds/client';
import React from 'react';
import {useSelector} from 'react-redux';

import {CompanySlugEnter} from 'client/scenes/company-slug-enter';
import {PageLayout} from 'client/scenes/page-layout';
import {selectBrand} from 'client/store/app';
import {IconYandex} from 'client/ui/icons/icon-yandex';
import {IconYangoTech} from 'client/ui/icons/icon-yango-tech';
import {SelectCompanyTestId} from 'types/test-id';

import styles from './select-company-page.css';

const cn = block(styles, 'select-company-page');

export function SelectCompanyPage() {
    const brand = useSelector(selectBrand);
    return (
        <PageLayout noHeader>
            <div className={cn()} data-testid={SelectCompanyTestId.ROOT}>
                <div className={cn('header')}>
                    {brand === OrganizationBrand.YANDEX ? <IconYandex /> : <IconYangoTech />}
                </div>
                <CompanySlugEnter className={cn('form')} />
            </div>
        </PageLayout>
    );
}
