import {LanguageCode} from '@yandex-lavka-birds/b2b-common';
import {type LoadLanguageFn, I18n} from '@yandex-lavka-birds/i18n';

export type UILanguage =
    | LanguageCode.AR
    | LanguageCode.AZ
    | LanguageCode.EN
    | LanguageCode.ES
    | LanguageCode.KY
    | LanguageCode.RU
    | LanguageCode.TR
    | LanguageCode.UZ;

const ar = () => import(/* webpackChunkName: "i18n-ar" */ 'i18n/resources/ar');
const az = () => import(/* webpackChunkName: "i18n-az" */ 'i18n/resources/az');
const en = () => import(/* webpackChunkName: "i18n-en" */ 'i18n/resources/en');
const es = () => import(/* webpackChunkName: "i18n-es" */ 'i18n/resources/es');
const ky = () => import(/* webpackChunkName: "i18n-ky" */ 'i18n/resources/ky');
const ru = () => import(/* webpackChunkName: "i18n-ru" */ 'i18n/resources/ru');
const tr = () => import(/* webpackChunkName: "i18n-tr" */ 'i18n/resources/tr');
const uz = () => import(/* webpackChunkName: "i18n-uz" */ 'i18n/resources/uz');

const langPromises: Record<UILanguage, LoadLanguageFn> = {ar, en, es, ru, uz, ky, tr, az};
const defaultLang = LanguageCode.EN;

export const i18n = new I18n<UILanguage>(langPromises, defaultLang);
