import {Api} from '@yandex-lavka-birds/client';

import {makeApiUrl, makeCompanyApiUrl} from '@/src/lib/make-api-url';
import {logger} from 'client/lib/error-logger';
import {API_VERSION} from 'constants/request';

import {applyMiddlewares} from './middlewares';

export const api = new Api({
    logger,
    apiVersion: API_VERSION,
    makeApiUrl
});

export const companyApi = new Api({
    logger,
    apiVersion: API_VERSION,
    makeApiUrl: makeCompanyApiUrl
});

applyMiddlewares(api);
applyMiddlewares(companyApi);
