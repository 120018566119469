import {type GetAbsPaths, AutoHiddenLevels, makeAbsPaths} from '@yandex-lavka-birds/b2b-common';

import {Role} from 'types/roles';

export const PERMISSIONS_REL = {
    BASE: AutoHiddenLevels.CONSTRUCTOR_0,
    USERS: {
        BASE: 'users',
        ACTION: {
            BASE: AutoHiddenLevels.ACTION_2N,
            LIST: 'list',
            LIST_COURIERS: 'list_couriers',
            MANAGE: 'manage',
            CREATE: 'create'
        }
    },
    USER_ROLES: {
        BASE: 'user_roles',
        ACTION: {
            BASE: AutoHiddenLevels.ACTION_2N,
            READ: 'read',
            REQUEST: 'request',
            /**
             * @deprecated Удалить после удаления ручки bulk-change-roles
             */
            ADD: 'add',
            REVOKE: 'revoke'
        }
    },
    ROLES: {
        BASE: 'roles',
        ACTION: {
            BASE: AutoHiddenLevels.ACTION_2N,
            READ: 'read',
            CONTROL_HIDDEN: 'control_hidden',
            WRITE: 'write'
        }
    },
    POLICIES: {
        BASE: 'policies',
        ACTION: {
            BASE: AutoHiddenLevels.ACTION_2N,
            READ: 'read',
            WRITE: 'write'
        }
    },
    COMPANIES: {
        BASE: 'companies',
        ACTION: {
            BASE: AutoHiddenLevels.ACTION_2N,
            LIST: 'list'
        }
    },
    DRAFT_POLICIES: {
        BASE: 'draft_policies',
        ACTION: {
            BASE: AutoHiddenLevels.ACTION_2N,
            READ: 'read',
            WRITE: 'write'
        }
    }
} as const;

export const PERMISSIONS = makeAbsPaths(PERMISSIONS_REL);

export type PermissionPath = GetAbsPaths<typeof PERMISSIONS>;

export const permissionMap = {
    [Role.ADMIN]: [
        PERMISSIONS.USERS.ACTION.LIST,
        PERMISSIONS.USERS.ACTION.LIST_COURIERS,
        PERMISSIONS.USERS.ACTION.MANAGE,
        PERMISSIONS.USERS.ACTION.CREATE,
        PERMISSIONS.USER_ROLES.ACTION.READ,
        PERMISSIONS.USER_ROLES.ACTION.REQUEST,
        PERMISSIONS.USER_ROLES.ACTION.ADD,
        PERMISSIONS.USER_ROLES.ACTION.REVOKE,
        PERMISSIONS.ROLES.ACTION.READ,
        PERMISSIONS.ROLES.ACTION.WRITE,
        PERMISSIONS.ROLES.ACTION.CONTROL_HIDDEN,
        PERMISSIONS.POLICIES.ACTION.READ,
        PERMISSIONS.POLICIES.ACTION.WRITE,
        PERMISSIONS.COMPANIES.ACTION.LIST,
        PERMISSIONS.DRAFT_POLICIES.ACTION.READ,
        PERMISSIONS.DRAFT_POLICIES.ACTION.WRITE
    ],
    [Role.COURIER_VIEWER]: [PERMISSIONS.USERS.ACTION.LIST_COURIERS],
    [Role.USER]: [
        PERMISSIONS.USERS.ACTION.LIST,
        PERMISSIONS.ROLES.ACTION.READ,
        PERMISSIONS.USER_ROLES.ACTION.READ,
        PERMISSIONS.USER_ROLES.ACTION.REQUEST
    ]
};

export const USER_ROLES_REQUEST_PERMISSIONS_SLUG_PATH = [
    PERMISSIONS.USER_ROLES.ACTION.REQUEST,
    PERMISSIONS.USER_ROLES.ACTION.ADD,
    PERMISSIONS.USER_ROLES.ACTION.REVOKE
];
