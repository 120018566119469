import type {I18nType} from '@yandex-lavka-birds/i18n';
import {setLocale} from 'yup';

export const setYupLocale = (i18n: I18nType): void => {
    setLocale({
        mixed: {
            required: i18n('validation-errors', 'required')
        },
        string: {
            email: i18n('validation-errors', 'email')
        },
        array: {
            min: ({min}) => i18n('validation-errors', 'array-min', {count: min})
        }
    });
};
