import {useActions} from '@yandex-lavka-birds/client';
import React from 'react';

import {NOTIFICATION_CLOSE_ANIMATION_DURATION} from 'client/scenes/notifications/constants';
import {notificationsActions} from 'client/scenes/notifications/notifications-reducer';

interface UseNotificationCloseAnimationOptions {
    notificationId: string;
    isClosing: boolean;
    afterClose?: () => void;
}

export const useNotificationCloseAnimation = ({
    notificationId,
    isClosing,
    afterClose
}: UseNotificationCloseAnimationOptions): void => {
    const hideNotification = useActions(notificationsActions.hide);

    React.useEffect(() => {
        if (!isClosing) {
            return;
        }

        const hideTimeout = window.setTimeout(() => {
            hideNotification(notificationId);
            afterClose?.();
        }, NOTIFICATION_CLOSE_ANIMATION_DURATION);

        return () => {
            window.clearTimeout(hideTimeout);
        };
    }, [afterClose, hideNotification, isClosing, notificationId]);
};
