import {createSelector} from '@reduxjs/toolkit';
import {IdentityBasketUserKind} from '@yandex-lavka-birds/b2b-common';

import {Permissions} from '@/src/lib/permissions';
import {config} from 'client/config';
import type {RootState} from 'client/store/configure-store';

export const selectUser = (state: RootState) => state.app.user;
export const selectUserId = (state: RootState) => state.app.user?.id;
export const selectCompanyUserKinds = (state: RootState) => state.app.company?.userKinds;
export const selectCompanyMainUserKind = createSelector(selectCompanyUserKinds, (userKinds) =>
    userKinds?.find((userKind) => [IdentityBasketUserKind.GLOBAL, IdentityBasketUserKind.STAFF].includes(userKind))
);
export const selectCompany = (state: RootState) => state.app.company;
export const selectCompanySlug = (state: RootState) => state.app.company?.slug;
export const selectCompanyId = (state: RootState) => state.app.company?.id;
export const selectCompanyIsService = (state: RootState) => state.app.company?.isService;
export const selectStaticPath = (state: RootState) => state.app.staticPath;
export const selectCsrfToken = (state: RootState) => state.app.csrfToken;
export const selectSessionId = (state: RootState) => state.app.sessionId;
export const selectBaseHost = (state: RootState) => state.app.baseHost;
export const selectFeaturesOverride = (state: RootState) => state.app.featuresOverride;
export const selectAvailableUILanguages = (state: RootState) => state.app.availableUILanguages;
export const selectCurrentCompanyUILanguages = (state: RootState) => state.app.company?.uiLanguages ?? [];
export const selectBrand = (state: RootState) => state.app.brand;

export const selectBasketIdentitySource = (state: RootState) => state.app.basketIdentitySource;
export const selectUserRoles = (state: RootState) => state.app.userRoles ?? [];
export const selectUserPermissions = (state: RootState) => state.app.userPermissions ?? [];
export const selectDisabledPermissions = (state: RootState) => state.app.disabledPermissions ?? [];

export const selectPermissions = createSelector(
    selectUser,
    selectBasketIdentitySource,
    selectUserPermissions,
    selectCompanySlug,
    selectCompanyId,
    selectCompanyIsService,
    selectDisabledPermissions,
    (user, identitySource, userPermissions, companySlug, companyId, companyIsService, disabledPermissions) =>
        new Permissions({
            currentBasketIdentitySource: identitySource,
            currentUser: user,
            currentUserPermissions: userPermissions,
            currentCompanySlug: companySlug,
            currentCompanyId: companyId,
            currentCompanyIsService: companyIsService,
            features: config.features,
            disabledPermissions
        })
);

export const selectIsCompanyWithCouriers = (state: RootState) =>
    state.app.company?.userKinds?.includes(IdentityBasketUserKind.COURIER) ?? false;
export const selectIsServiceCompany = (state: RootState) => state.app.company?.isService;
