import {Link, Spin} from '@lavka/ui-kit';
import {block, useActions, useBooleanState} from '@yandex-lavka-birds/client';
import React from 'react';
import {useSelector} from 'react-redux';
import {useRouteMatch} from 'react-router';

import {useI18n} from '@/src/i18n';
import {NotificationIcon} from 'client/scenes/notifications/components/notification-icon';
import {useNotificationAutoClose} from 'client/scenes/notifications/hooks/use-notification-auto-close';
import {useNotificationCloseAnimation} from 'client/scenes/notifications/hooks/use-notification-close-animation';
import {NotificationType} from 'client/scenes/notifications/types';
import {ClientUsersImportStatus} from 'client/scenes/users/users-import-controller/types';
import {
    type UsersImportState,
    usersImportActions
} from 'client/scenes/users/users-import-controller/users-import-reducer';
import {selectUsersImportState} from 'client/scenes/users/users-import-controller/users-import-selectors';
import type {RootState} from 'client/store/configure-store';
import {useGetUsersListPagePath} from 'client/store/router/router-actions';
import {PAGE_PATHS} from 'constants/routes';

// eslint-disable-next-line no-restricted-imports
import commonNotificationStyles from '../../notifications.css';
import styles from './users-import-notification.css';

const notificationsCn = block(commonNotificationStyles, 'notifications');
const cn = block(styles, 'users-import-notification');

interface UsersImportNotificationProps {
    notificationId: string;
}

export function UsersImportNotification({notificationId}: UsersImportNotificationProps) {
    const usersImportState = useSelector(selectUsersImportState);

    const setUsersImportState = useActions(usersImportActions.setState);
    const afterClose = React.useCallback(() => {
        setUsersImportState({status: ClientUsersImportStatus.NOT_STARTED});
    }, [setUsersImportState]);

    const {value: isClosing, setTrue: close} = useBooleanState();
    useNotificationCloseAnimation({notificationId, isClosing, afterClose});

    if (usersImportState.status === ClientUsersImportStatus.NOT_STARTED) {
        return null;
    }

    return (
        <div className={notificationsCn('notification', {type: NotificationType.USERS_IMPORT, closing: isClosing})}>
            <NotificationContent importState={usersImportState} onClose={close} />
        </div>
    );
}

interface NotificationContentProps {
    importState: UsersImportState;
    onClose?: () => void;
}

const NotificationContent = ({importState, onClose}: NotificationContentProps) => {
    switch (importState.status) {
        case ClientUsersImportStatus.PROGRESS:
            return <ContentInProgress />;

        case ClientUsersImportStatus.SUCCESS:
        case ClientUsersImportStatus.FAIL:
            return <ContentSuccessOrFail importState={importState} onClose={onClose} />;
    }

    return null;
};

const ContentInProgress = () => {
    const i18n = useI18n();

    return (
        <>
            <Spin className={cn('spin')} size={20} />
            {i18n('users-import', 'import-in-progress')}
        </>
    );
};

interface ContentSuccessProps {
    importState: RootState['scenes']['usersImport'];
    onClose?: () => void;
}

const ContentSuccessOrFail = ({importState, onClose}: ContentSuccessProps) => {
    const i18n = useI18n();
    const getUsersListPagePath = useGetUsersListPagePath();
    const usersListPageMatch = useRouteMatch({path: PAGE_PATHS.USERS_LIST, exact: true});

    const {isClosing} = useNotificationAutoClose();
    React.useEffect(() => {
        if (isClosing) {
            onClose?.();
        }
    }, [isClosing, onClose]);

    let text: React.ReactNode;
    if (importState.status === ClientUsersImportStatus.SUCCESS) {
        text = importState.usersAdded
            ? i18n('users-import', 'employees-added-count', {count: importState.usersAdded})
            : i18n('users-import', 'import-success-fallback');
    } else {
        if (!importState.usersAdded && !importState.usersFailed) {
            text = i18n('users-import', 'import-fail-fallback');
        } else {
            const textParts: React.ReactNode[] = [];

            if (importState.usersAdded) {
                textParts.push(i18n('users-import', 'employees-added-count', {count: importState.usersAdded}));
            }
            if (importState.usersFailed) {
                textParts.push(i18n('users-import', 'employees-failed-count', {count: importState.usersFailed}));
            }

            text = textParts.join('. ');
        }
    }

    return (
        <>
            <NotificationIcon
                className={notificationsCn('notification-icon')}
                type={
                    importState.status === ClientUsersImportStatus.SUCCESS
                        ? NotificationType.SUCCESS
                        : NotificationType.ERROR
                }
                size={20}
            />
            {text}
            {!usersListPageMatch && (
                <Link className={cn('list-link')} type="router" to={getUsersListPagePath()} onClick={onClose}>
                    {i18n('users-import', 'to-employees-list')}
                </Link>
            )}
        </>
    );
};
