import {CSRF_TOKEN_HEADER_NAME, SESSION_ID_HEADER_NAME} from '@yandex-lavka-birds/b2b-common';
import type {Api} from '@yandex-lavka-birds/client';

import {serializeFeatures} from '@/src/lib/features';
import {shouldCheckCsrf} from '@/src/lib/should-check-csrf';
import {store} from 'client/store';
import {appActions, selectCsrfToken, selectFeaturesOverride, selectSessionId} from 'client/store/app';
import {FEATURES_HEADER_NAME} from 'constants/constants';

export const applyMiddlewares = (api: Api) => {
    api.addRequestMiddleware(({init}) => {
        init.headers = new Headers(init.headers);

        const storeState = store.getState();

        const sessionId = selectSessionId(storeState);
        init.headers.append(SESSION_ID_HEADER_NAME, sessionId);

        const csrfToken = selectCsrfToken(storeState);
        if (shouldCheckCsrf(init.method) && csrfToken) {
            init.headers.append(CSRF_TOKEN_HEADER_NAME, csrfToken);
        }

        const featuresOverride = selectFeaturesOverride(storeState);
        if (featuresOverride) {
            init.headers.append(FEATURES_HEADER_NAME, serializeFeatures(featuresOverride));
        }
    });

    api.addResponseMiddleware((response) => {
        const csrfToken = response.headers.get(CSRF_TOKEN_HEADER_NAME);
        if (csrfToken) {
            store.dispatch(appActions.setCsrfToken(csrfToken));
        }
    });
};
