import {block} from '@yandex-lavka-birds/client';
import React from 'react';

import {Header} from './components/header';

import styles from './page-layout.css';

interface PageLayoutProps {
    className?: string;
    headerContent?: React.ReactNode;
    noHeader?: boolean;
    children?: React.ReactNode;
}

export function PageLayout({className, headerContent, noHeader, children}: PageLayoutProps) {
    const cn = block(styles, 'page-layout', className);

    return (
        <div className={cn()}>
            {noHeader ? null : <Header centerContent={headerContent} />}
            <div className={cn('content')}>{children}</div>
        </div>
    );
}
