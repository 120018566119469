import {BaseApiResponseError} from '@yandex-lavka-birds/errors';

import {TIMEOUT_ERROR_MESSAGE} from '@/src/constants';

export const retry = (count: number, error: unknown) => {
    if (count > 3) {
        return false;
    }

    if (error instanceof BaseApiResponseError && error.message === TIMEOUT_ERROR_MESSAGE) {
        return true;
    }
    return false;
};
