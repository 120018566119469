import type {I18n, I18nRawType, I18nType} from '@yandex-lavka-birds/i18n';
import type {Locale} from 'date-fns';
import React, {
    type ReactElement,
    type ReactNode,
    createContext,
    useCallback,
    useContext,
    useMemo,
    useState
} from 'react';

import type {UILanguage} from './langs';

interface LocalizationContext {
    uiLang: UILanguage;
    dateLocale: Locale;
    setUiLang: (lang: UILanguage) => void;
    i18n: I18nType;
    i18nRaw: I18nRawType;
}

interface Props {
    initLang?: UILanguage;
    onUpdateLang?(lang: UILanguage): void;
    i18n: I18n<UILanguage>;
    children: ReactNode;
}

const I18nContext = createContext({} as LocalizationContext);

function I18nProvider({children, i18n, initLang = i18n.defaultLang, onUpdateLang}: Props): ReactElement {
    const [uiLang, setUiLang] = useState<UILanguage>(initLang);

    const setLoadableUiLang = useCallback(
        (lang: UILanguage) => {
            const uiLang = i18n.isSupportedLang(lang) ? lang : i18n.defaultLang;
            void i18n.loadI18n(uiLang).then(() => {
                setUiLang(uiLang);
                onUpdateLang?.(uiLang);
            });
        },
        [i18n, onUpdateLang]
    );

    const i18nLang = useMemo(() => i18n.getI18n(uiLang), [i18n, uiLang]);
    const i18nRaw = useMemo(() => i18n.getI18nRaw(uiLang), [i18n, uiLang]);
    const dateLocale = useMemo(() => i18n.getDateLocale(uiLang), [i18n, uiLang]);

    return (
        <I18nContext.Provider value={{uiLang, setUiLang: setLoadableUiLang, i18n: i18nLang, i18nRaw, dateLocale}}>
            {children}
        </I18nContext.Provider>
    );
}

function useI18n() {
    return useContext(I18nContext).i18n;
}

function useI18nRaw() {
    return useContext(I18nContext).i18nRaw;
}

function useI18nContext() {
    return useContext(I18nContext);
}

export {useI18nContext, useI18n, useI18nRaw, I18nProvider};
